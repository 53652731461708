import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import 'tachyons'
import Spinner from './Components/Spinner/Spinner';


const rootElement = document.getElementById('root')

if(rootElement.hasChildNodes()){
  ReactDOM.hydrate(
      <React.StrictMode>
        <BrowserRouter>
          <Suspense fallback={<Spinner/>}> 
            <App />
          </Suspense>
        </BrowserRouter>
      </React.StrictMode>,
      rootElement
  )
}else{
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Suspense fallback={<Spinner/>}> 
          <App />
        </Suspense>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  )
}

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Suspense fallback={<Spinner/>}> 
//         <App />
//       </Suspense>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
